import React from "react";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../../../utils/LanguageContext";
const DashboardWallet = () => {
  const { languageSlug } = useLanguage();
  return (
    <div className="dashboard_wallet">
      <div className="dashboard_wallet_balance">
        <span>Общий баланс</span>
        <NavLink to={`/${languageSlug}/wallet/currency`}>Торговать</NavLink>
      </div>

      <div className="dashboard_wallet_number">
        <span>1,85 $ USD</span>
      </div>

      <div className="dashboard_wallet_pln">
        <span>PnL</span>
        <span>+0,070000 $(+4,02 %)</span>
      </div>
    </div>
  );
};

export default DashboardWallet;
