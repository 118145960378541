import { useEffect } from "react";
import { createContext, useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLang } from "../store/slices/userSlice";

const LanguageContext = createContext(undefined);

export function LanguageProvider({ children }) {
  const dispatch = useDispatch();
  const languageSlug = useSelector((state) => state.user.lang);
  const navigate = useNavigate();
  const location = useLocation();
  const changeLanguageSlug = (newSlug) => {
    dispatch(setLang(newSlug));
  };

  // for first request, take from url lang if exists
  useEffect(() => {
    const currentPath = location.pathname;
    const langFromUrl = location.pathname.split("/")[1];

    const pathWithoutLanguageSlug = currentPath.replace(`/${langFromUrl}`, "");

    if (langFromUrl && (langFromUrl === "ru" || langFromUrl === "ua")) {
      changeLanguageSlug(langFromUrl);
      navigate(`/${langFromUrl}${pathWithoutLanguageSlug}`, { replace: true });
    }
  }, []);
  return (
    <Routes>
      <Route
        path={`/${languageSlug}/*`}
        element={
          <LanguageContext.Provider
            value={{ languageSlug, changeLanguageSlug }}
          >
            {children}
          </LanguageContext.Provider>
        }
      />
      <Route path="*" element={<Navigate to={`/${languageSlug}/`} />} />
    </Routes>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
}
