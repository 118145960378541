import React from "react";
import NavCurrency from "../../components/nav/NavCurrency";
import InputSearchCurrency from "./components/InputSearchCurrency";
import NavBack from "../../components/nav/NavBack";
import CurrencyItem from "./components/CurrencyItem";

import "../../style/findCurrency.css";
const FindCurrency = ({ type }) => {
  return (
    <div style={{ width: "100vw", marginBottom: "80px" }}>
      <NavBack />
      <NavCurrency />
      <InputSearchCurrency />

      <div className="currency_items-wrapper">
        <CurrencyItem name={"BTC / USDT"} />
      </div>
    </div>
  );
};

export default FindCurrency;
