import React from "react";
import { Route, Routes } from "react-router-dom";
import useWindowDimensions from "../useHook/useWindowDimensions";

import Nav from "../components/nav/Nav";
// Page
import Home from "../views/home/Home";
import Friends from "../views/friends/Friends";
import Wallet from "../views/wallet/Wallet";
import Futures from "../views/futures/Futures";
import Tasks from "../views/tasks/Tasks";

import FindCurrency from "../views/findCurrency/FindCurrency";

const AppRouter = () => {
  const { widthScreen } = useWindowDimensions();
  return (
    <>
      {widthScreen > 500 ? (
        <p style={{ color: "red", fontWeight: "bold" }}>
          Только мобильная версия
        </p>
      ) : (
        <>
          <Nav />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/friends" element={<Friends />} />

              <Route path="/wallet/*" element={<Wallet />} />

              <Route
                path="/wallet/currency"
                element={<FindCurrency type={"wallet"} />}
              />

              <Route path="/futures" element={<Futures />} />

              <Route path="/tasks" element={<Tasks />} />

              <Route path="*" element={<Home />} />
            </Routes>
          </main>
        </>
      )}
    </>
  );
};

export default AppRouter;
