import React from "react";

import present_icon from "../../style/images/present_icon.png";
import user_icon from "../../style/images/user_icon.png";
import copy_icon from "../../style/images/copy_icon.png";
import "../../style/friends.css";
const Friends = () => {
  return (
    <div style={{ width: "100vw", marginBottom: "80px" }}>
      <div className="friends_title">
        <span>Пригласите друзей!</span>
        <span>Вы и ваш друг получите бонусы</span>
      </div>

      <div className="friends_present">
        <img
          className="friends_present_icon"
          src={present_icon}
          alt="present icon"
        />
        <div className="friends_present_text">
          <div className="friends_present_title">Пригласить друга</div>
          <div className="friends_present_promo">
            <span>1000$</span>
            <span>для вас и вашего друга</span>
          </div>
        </div>
      </div>

      <div className="friends_present">
        <img
          className="friends_present_icon"
          src={present_icon}
          alt="present icon"
        />
        <div className="friends_present_text">
          <div className="friends_present_title">
            Пригласить друга с Telegram Premium
          </div>
          <div className="friends_present_promo">
            <span>1000$</span>
            <span>для вас и вашего друга</span>
          </div>
        </div>
      </div>

      <div className="friends_list">
        <span className="friends_list_title">Список ваших друзей</span>

        <div className="friends_list_items">
          <span className="friends_list_items_none">
            Вы еще никого не пригласили
          </span>
        </div>
      </div>

      <div className="friends_buttons">
        <div className="friends_buttons_left">
          <span>Отправить письмо</span>
          <img src={user_icon} alt="user icon" />
        </div>
        <div className="friends_buttons_right">
          <img src={copy_icon} alt="copy icon" />
        </div>
      </div>
    </div>
  );
};

export default Friends;
