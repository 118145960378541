import React from "react";
import { useNavigate } from "react-router-dom";

import back_icon from "../../style/images/back_icon.png";

const NavBack = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="nav-back" onClick={handleBack} style={styles.navBack}>
      <img src={back_icon} alt="back icon" /> Назад
    </div>
  );
};

const styles = {
  navBack: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "12px 20px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "16px",
    gap: "16px",
    margin: "16px",
  },
};

export default NavBack;
