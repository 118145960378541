import React, { useState } from "react";
import eu_icon from "../../style/images/eu_icon.png";
import usa_icon from "../../style/images/usa_icon.png";
import cripta_icon from "../../style/images/cripta_icon.png";

import "../../style/wallet.css";
const NavCurrency = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleNavClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className="wallet_nav">
        <div
          className={`wallet_nav_item ${activeIndex === 0 ? "active" : ""}`}
          onClick={() => handleNavClick(0)}
        >
          <img src={cripta_icon} alt="Crypto Icon" />
        </div>

        <div
          className={`wallet_nav_item ${activeIndex === 1 ? "active" : ""}`}
          onClick={() => handleNavClick(1)}
        >
          <img src={usa_icon} alt="USA Icon" />
        </div>

        <div
          className={`wallet_nav_item ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => handleNavClick(2)}
        >
          <img src={eu_icon} alt="EU Icon" />
        </div>

        <div
          className={`wallet_nav_item ${activeIndex === 3 ? "active" : ""}`}
          onClick={() => handleNavClick(3)}
        >
          <img src={eu_icon} alt="EU" />
        </div>
      </div>
    </>
  );
};

export default NavCurrency;
