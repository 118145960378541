import React from "react";

import coin_icon from "../../style/images/coin_icon.png";
import telegram_icon from "../../style/images/telegram_icon.png";
import youtube_icon from "../../style/images/youtube_icon.png";

import "../../style/task.css";
const Tasks = () => {
  return (
    <div style={{ width: "100vw", marginBottom: "80px" }} className="task">
      <div className="task_top">
        <img src={coin_icon} alt="coin icon" />
        <span>Зарабатывай больше денег</span>
      </div>

      <div className="task_items">
        <div className="task_items_item">
          <img src={telegram_icon} alt="telegram icon" />
          <div>
            <span>Присоединяйтесь к нашему Telegram каналу</span>
            <span>+1000$</span>
          </div>
        </div>

        <div className="task_items_item">
          <img src={youtube_icon} alt="youtube icon" />
          <div>
            <span>Умные приемы управления портфелем!</span>
            <span>+1000$</span>
          </div>
        </div>

        <div className="task_items_item">
          <img src={telegram_icon} alt="telegram icon" />
          <div>
            <span>Присоединяйтесь к нашему Telegram каналу</span>
            <span>+1000$</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
