import React, { useState } from "react";

const InputSearchCurrency = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <label htmlFor="searchInput" className="currency_label_searchInput">
      <input
        id="searchInput"
        type="text"
        placeholder="Поиск"
        value={searchTerm}
        onChange={handleInputChange}
        className="currency_searchInput"
      />
    </label>
  );
};

export default InputSearchCurrency;
