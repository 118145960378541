import React from "react";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";

import home_icon from "../../style/images/home.png";
import friends_icon from "../../style/images/friends.png";
import wallet_icon from "../../style/images/wallet.png";
import futures_icon from "../../style/images/futures.png";
import tasks_icon from "../../style/images/tasks.png";

import "../../style/nav.css";
const Nav = () => {
  const { languageSlug } = useLanguage();

  return (
    <>
      <nav className="navMainMobile">
        <div className="navMainMobile_link-container">
          <NavLink to={`/${languageSlug}/`} end>
            <img src={home_icon} />
          </NavLink>
          <NavLink to={`/${languageSlug}/friends`}>
            <img src={friends_icon} />
          </NavLink>

          <NavLink to={`/${languageSlug}/wallet`}>
            <img src={wallet_icon} />
          </NavLink>
          <NavLink to={`/${languageSlug}/futures`}>
            <img src={futures_icon} />
          </NavLink>
          <NavLink to={`/${languageSlug}/tasks`}>
            <img src={tasks_icon} />
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default Nav;
