import React from "react";
import NavCurrency from "../../components/nav/NavCurrency";
import DashboardWallet from "./components/DashboardWallet";
import BalancesWallet from "./components/BalancesWallet";
const Wallet = () => {
  return (
    <div style={{ width: "100vw", marginBottom: "80px" }}>
      <NavCurrency />
      <DashboardWallet />
      <BalancesWallet />
    </div>
  );
};

export default Wallet;
