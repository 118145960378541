import React from "react";
import BalancesWalletItem from "./BalancesWalletItem";
const BalancesWallet = () => {
  return (
    <div className="balances_wallet">
      <span className="balances_wallet_title">Балансы</span>
      <div className="balances_wallet_items-wrapper">
        <BalancesWalletItem />
        <BalancesWalletItem />
        <BalancesWalletItem />
      </div>
    </div>
  );
};

export default BalancesWallet;
