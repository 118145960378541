import React from "react";

const CurrencyItem = ({ name, price, growth }) => {
  return (
    <div className="currency_item">
      <span>{name}</span>
      <div className="currency_item_right">
        <span>{price}</span>
        <span>{growth}% </span>
      </div>
    </div>
  );
};

export default CurrencyItem;
