import React, { useEffect, useState } from "react";
import "../../style/home.css";
const Home = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Проверяем, что Telegram API доступен
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      const initData = tg.initDataUnsafe;
      console.log("Данные Telegram:", initData);

      setUser(tg || null);

      // Расширяем Web App на весь экран
      tg.expand();
    } else {
      console.error("Telegram Web App API не доступен.");
    }
  }, []);

  console.log("For Pavel", user);

  return (
    <div className="home">
      {user ? <h1>Привет, {user.first_name}!</h1> : <h1>Загрузка...</h1>}
    </div>
  );
};

export default Home;
