import React from "react";

const BalancesWalletItem = () => {
  return (
    <div className="balances_wallet_item">
      <div>
        <span>SOL</span>
        <span>0,00913</span>
      </div>

      <div>
        <span>Solana</span>
        <span>1,63 $</span>
      </div>

      <div>
        <span>Cредняя стоимость</span>
        <span>14,17 $</span>
      </div>

      <div>
        <span>PnL за сегодня</span>
        <span>+0,07 $ (+4,28 %)</span>
      </div>
    </div>
  );
};

export default BalancesWalletItem;
